import { useEffect } from 'react'

import { useRouter } from 'next/router'

import usePermissions from 'hooks/permissions/usePermissions'
import Routes from 'types/enums/routes'

const HomePage = () => {
  const router = useRouter()
  const { hasRoutePermissions } = usePermissions()

  useEffect(() => {
    if (hasRoutePermissions(Routes.BOOKINGS)) {
      router.push(Routes.BOOKINGS)
    } else router.push(Routes.SETTINGS)
  }, [router, hasRoutePermissions])
}

export default HomePage
